import React from "react"
import Layout from "../components/layout"
import CutContainer from "../components/cutContainer"
import { greenGradient, grayGradient4 } from "../services/constants"
import styles from "./support.module.scss"
import infoButtonReusedStyle from "./infoButton.module.scss"
import indexReusedStyle from "./index.module.scss"
import globalStyles from "../services/globalStyles.module.scss"
import Button from "../components/button"
import gears from "../images/gears.webp"
import arrow from "../images/common/icon_arrow_green.svg"
import InfoBox from "../components/infoBox"
import GetStarted from "../components/getStarted"
import Header from "../components/header"
import Footer from "../components/footer"
import { graphql } from "gatsby";

class SupportPage extends React.Component {
  onClick(url) {
    var win = window.open(url, '_blank');
    win.focus();
  }
  render() {
    const { data: { texts: { _ }, supportContent }, pageContext } = this.props;
    const languageCode = pageContext && pageContext.lang ? pageContext.lang : "en";

    return (
      <Layout header={<Header num={1} translations={_} headerFullYOffset={500} mobileHeaderFullYOffset={40} fixed whiteFont languageCode={languageCode} />} translations={_} languageCode={languageCode}>
        <CutContainer
          style={{
            background: greenGradient,
            position: "relative",
          }}
          sizeOfCut={2}
          styleClass={styles.cutContainer}
        >
          <h1 className={styles.header}>
            {_.support.header}
          </h1>
          <p className={styles.paragraph}>
            {_.support.paragraph}
          </p>
        </CutContainer>
        <div className={[styles.options, globalStyles.contentContainer170].join(' ')}>
          {supportContent.edges.map(({ node: { frontmatter } }, index) => {
            return <div key={index} className={styles.option} onClick={() => { this.onClick(frontmatter.url) }}>
              <h2>{frontmatter.type && frontmatter.type.toUpperCase()}</h2>
              <div className={styles.titleContainer}>
                <h1>{frontmatter.title !== 'reports_and_monitoring' ? _.support.boxes[frontmatter.title] : 'Reports & Monitoring '}</h1>
              </div>
              <Button>
                {_.support.start.toUpperCase()}
              </Button>
            </div>
          })}
        </div>
        <CutContainer
          style={{
            background: grayGradient4,
            position: "relative",
          }}
          sizeOfCut={2}
          styleClass={[globalStyles.contentContainer248, styles.cutContainer2]}
          topCut
        >
          <div className={globalStyles.hideOnMobile}>
            <img className={styles.gears} src={gears} />
          </div>
          <div className={styles.content}>
            <h1>
              {_.support.knowledgebase.header}
            </h1>
            <p>
              {_.support.knowledgebase.paragraph}
            </p>
            <span onClick={() => { this.onClick("https://sidelinesports.zendesk.com") }}>
              <a>
                {_.support.knowledgebase.button}
              </a>
              <div>
                <img src={arrow} />
              </div>
            </span>
          </div>
        </CutContainer>
        <div className={globalStyles.contentContainer}>
          <div style={{ position: "relative" }} className={[globalStyles.infoBoxContainer, styles.infoBoxContainer].join(' ')}>
            <InfoBox classStyle={globalStyles.infoBox} imgNumber={3} mobileImg>
              <h1 style={{ color: "#3C434C" }} className={[styles.infoBoxHeader, styles.infoBoxHeader].join(' ')}>
                {_.support.infobox.header}
              </h1>
              <div className={infoButtonReusedStyle.buttonContainer}>
                <button className={infoButtonReusedStyle.infoButton}>
                  <a style={{ textDecoration: "none" }} className={infoButtonReusedStyle.infoButtonContentContainer} href="mailto:support@sidelinesports.com">
                    <div className={[indexReusedStyle.infoButtonTextContainer, globalStyles.hyperlink].join(' ')}>
                      {_.support.infobox.button}
                    </div>
                    <div
                      className={[
                        infoButtonReusedStyle.infoButtonIconContainer,
                        globalStyles.hideOnMobile,
                      ].join(" ")}
                    >
                      <img
                        style={{ marginLeft: 14, marginTop: -4, width: 32, height: 32 }}
                        src={arrow}
                        alt="Video"
                      />

                    </div>
                    <div
                      className={[
                        infoButtonReusedStyle.infoButtonIconContainer,
                        globalStyles.hideOnDesktop,
                      ].join(" ")}
                    >
                      <img
                        style={{ marginLeft: "auto", width: 16, height: 16 }}
                        src={arrow}
                        alt="Video"
                      />

                    </div>
                  </a>
                </button>
              </div>
            </InfoBox>
          </div>
        </div>
        <GetStarted languageCode={languageCode} translations={_} />
        <Header num={2} translations={_} whiteFont className={globalStyles.hideOnMobile} seeThrough headerFullYOffset={500} languageCode={languageCode} />
        <Footer translations={_} languageCode={languageCode} />
      </Layout>
    )
  }
}
export default SupportPage

export const query = graphql`
query Support($langFile: String) {
  site {
    siteMetadata {
      ip_stack_key
    }
  }
  texts: file(relativePath: {eq: $langFile}) {
    _: childTextsYaml {
      ...HeaderTranslations
      ...FooterTranslations
      ...EmailTranslations
      ...TryXpsModalTranslations
      ready_to_start
      enter_your_email
      start_free_trial
      time_to_get_started
      support{
        header
        paragraph
        start
        knowledgebase {
          header
          paragraph
          button
        }
        infobox {
          header
          button
        }
        boxes {
          diagrams_animations
          getting_started
          messages
          strength_conditioning
          team_group_sessions
          user_management
          video_analysis
        }
      }
      chat {
        full_name
        email
        message
        send_message
        success_message
        success_info
        success
        message_placeholder
      }
    }
  },
  supportContent: allMarkdownRemark(sort: {order: ASC, fields: [frontmatter___index]},  filter: {fileAbsolutePath: {regex: "/supportContent/"}}) {
    edges {
      node {
        frontmatter {
          title
          type
          url
        }
      }
    }
  },
}`
