import React, { useState } from "react"
import { openModal } from "../services/modal"
import Button from "./button"
import styles from "./getStarted.module.scss"

const GetStarted = ({ languageCode, translations }) => (
  <div className={styles.wrapper}>
    <h2>{translations.time_to_get_started}</h2>
    <Button onClick={openModal} classStyle={styles.button}>
      {translations.try_xps.try_xps_for_free}
    </Button>
    <p>{translations.try_xps.no_credit_card}</p>
  </div>
)

export default GetStarted
